<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex flex-column secondary--text">
                <div class="d-flex align-center">
                    <span class="font-weight-bold">Requests</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="ml-1" v-on="on" @click="fetchRequests" text icon><v-icon>refresh</v-icon></v-btn>
                        </template>
                        <span>refresh</span>
                    </v-tooltip>
                </div>
                <span class="caption text-uppercase" v-if="fetchingUserOwnRequests || fetchingUserToApproveRequests">Fetching requests...</span>
                <span class="caption text-uppercase" v-else-if="selectedTab === tabOptions.OWN_REQUESTS">{{ userRequests.own.length }} requests found</span>
                <span class="caption text-uppercase" v-else-if="selectedTab === tabOptions.REQUESTS_TO_APPROVE">
                    {{ userRequests.toApprove.length }} requests found
                </span>
            </div>
        </v-card-title>
        <v-card-text>
            <v-tabs class="mt-5" v-model="selectedTab">
                <v-tab>Requests submitted</v-tab>
                <v-tab>Requests to approve</v-tab>
            </v-tabs>
            <v-tabs-items class="mt-7" v-model="selectedTab">
                <v-tab-item>
                    <requestsList :requestType="requestTypes.OWN_REQUESTS" :requestsData="userRequests.own" :fetchingRequests="fetchingUserOwnRequests" />
                </v-tab-item>
                <v-tab-item>
                    <requestsList
                        :requestType="requestTypes.REQUESTS_TO_APPROVE"
                        :requestsData="userRequests.toApprove"
                        :fetchingRequests="fetchingUserToApproveRequests" />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { invitationsAndRequests } from '@/mixins/invitationsAndRequests'
const requestsList = () => import('../components/TheUserRequestsList')
export default {
    components: { requestsList },
    mixins: [invitationsAndRequests],
    data() {
        return {
            selectedTab: 0,
            tabOptions: {
                OWN_REQUESTS: 0,
                REQUESTS_TO_APPROVE: 1
            }
        }
    },
    computed: {
        ...mapState('userStore', ['userRequests', 'fetchingUserOwnRequests', 'fetchingUserToApproveRequests'])
    },
    methods: {
        fetchRequests() {
            if (this.selectedTab === this.tabOptions.OWN_REQUESTS) {
                this.$store.dispatch('userStore/fetchUserOwnRequests')
            } else {
                this.$store.dispatch('userStore/fetchUserToApproveRequests')
            }
        }
    },
    mounted() {
        if (this.userRequests && this.userRequests.toApprove.length) {
            this.selectedTab = 1
        }
    },
    watch: {
        'userRequests.toApprove': function (nextVal, preVal) {
            if (nextVal.length) {
                this.selectedTab = 1
            }
        }
    }
}
</script>
